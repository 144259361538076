import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/MoveinGuideMessage",
    name: "MoveinGuideMessage",
    component: () => import("../views/AboutGuide.vue"),
    meta: {
      title: "",
    },
  },
  {
    path: "/ServiceGuideMessage",
    name: "ServiceGuideMessage",
    component: () => import("../views/AboutService.vue"),
    meta: {
      title: "案内詳細",
    },
  },
  {
    path: "/Outage",
    name: "Outage",
    component: () => import("../views/Outage.vue"),
    meta: {
      title: "解約代行依頼",
    },
  },
];
const DEFAULT_TITLE = "案内内容確認ページ";
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.afterEach((to) => {
  document.title = to.meta.title
    ? to.meta.title + " | " + DEFAULT_TITLE
    : DEFAULT_TITLE;
});

export default router;
