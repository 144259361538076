import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { DatePicker, Popup } from "vant";
import "element-plus/dist/index.css";
import axios from "axios"; //追記
import VueAxios from "vue-axios"; //追記
import "vant/lib/index.css";
let a = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
});
const app = createApp(App).use(store).use(router);
//app.use(Vue3Lottie, { name: "lottie" });
//app.use(ElementPlus);
app.use(VueAxios, a);
app.use(Popup);
app.use(DatePicker);
// let key = "Refresh";
// app.component(key, Refresh);
app.mount("#app");
