import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "main"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view");
  const _component_el_main = _resolveComponent("el-main");
  const _component_el_container = _resolveComponent("el-container");
  return _openBlock(), _createBlock(_component_el_container, null, {
    default: _withCtx(() => [_createVNode(_component_el_main, null, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_router_view)])]),
      _: 1
    })]),
    _: 1
  });
}